import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {InvisibleSmartCaptcha} from "@yandex/smart-captcha";

import {
  Typography,
  Button,
  TextField,
} from '@pik/pik-ui';
import {
  sendCode,
} from '../../../../../../services/recovery/actions';

import {
  Wrapper,
} from './styles';

class PasswordRecoveryPhone extends Component {
  static propTypes = {
    sendCodeAction: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
  };

  static defaultProps = {
    error: null,
  };

  state = {
    phone: null,
    phoneValid: false,
    visibleCaptcha: false,
    resetCaptcha: 0,
  };

  onChange = (name, value) => {
    this.setState({ [name]: value });
  };

  onValidate = (name, isValid) => {
    this.setState({ phoneValid: isValid });
  };

  sendRequest = (captcha) => {
    const { sendCodeAction } = this.props;
    const { phone, phoneValid } = this.state;

    if (phone && phoneValid && captcha) {
      sendCodeAction({ phone: phone.replace(/[^0-9]+/g, '') }, captcha);
    }
    this.setState((prevState) => ({
      resetCaptcha: prevState.resetCaptcha + 1,
      visibleCaptcha: false
    }));
  };

  handleChallengeHidden = () => this.setState({ visibleCaptcha: false });

  render() {
    const { loading, error } = this.props;
    const { phoneValid, phone, resetCaptcha, visibleCaptcha } = this.state;
    return (
        <>
          <Typography type="body" color="grayDark">
            Введите номер телефона, мы пришлем вам проверочный код
          </Typography>
          <Wrapper>
            <TextField
              type="newTel"
              value={phone}
              disabled={loading}
              error={error || ''}
              name="phone"
              placeholder="Телефон"
              inputStyle="accent"
              onChange={this.onChange}
              onValidate={this.onValidate}
            />
          </Wrapper>
          <Wrapper>
            <Button
              text="Получить код"
              type="primaryB"
              size="l"
              loading={loading}
              fullWidth
              disabled={!phoneValid}
              onClick={() => this.setState({ visibleCaptcha: true })}
            />
            <InvisibleSmartCaptcha
                key={resetCaptcha}
                sitekey="ysc1_jYkVUvWOFPskeUfzcP4N8XF2ks3Niwoy3NeVe8i717bfe85d"
                onChallengeHidden={this.handleChallengeHidden}
                visible={visibleCaptcha}
                hideShield
                onSuccess={this.sendRequest}
            />
          </Wrapper>
        </>
    );
  }
}

const mapStateToProps = ({ recoveryService }) => ({
  loading: recoveryService.loading,
  error: recoveryService.error,
  screenState: recoveryService.screenState,
});

const mapDispatchToProps = {
  sendCodeAction: sendCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordRecoveryPhone);
